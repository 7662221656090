import { forwardRef, useImperativeHandle, useCallback, useState, useEffect } from 'react';

import type { Placeholder, Question } from '@omq/types';
import {
  FeedbackWrapper,
  Footer,
  useBackendContext,
  useConfigContext,
  useEventContext,
} from '@omq/shared';

import { QuestionList } from './components/question-list/question-list';

import { useFormContent } from './hooks/form-content';
import { useQuestionSearch } from './hooks/question-search';

import { ContactAPI } from './api/contact';

import './contact.less';
import { ContactCategory, ContactEvents } from './events/contact-events';

/**
 * Type for component properties.
 */
type ContactProps = {
  element: HTMLElement;
  account: string;
  apiKey: string;
  analyze?: string | null;
  submit?: string | null;
  defaultQuestions?: Array<Question>;
  placeholder: Placeholder;
  categories: Array<ContactCategory>;
};

/**
 * Contact main component.
 *
 * @param {ContactProps} props - Component properties
 * @param {{}} ref - Reference to component
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
function Contact(
  {
    element,
    account,
    apiKey,
    analyze,
    submit,
    defaultQuestions,
    placeholder: defaultPlaceholder,
    categories,
  }: ContactProps,
  ref,
): JSX.Element {
  const backend = useBackendContext();
  const config = useConfigContext();

  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);
  const [searchCategory, setSearchCategory] = useState<number | null>(null);
  const [forceSearch, setForceSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [placeholder, setPlaceholder] = useState<Placeholder>(defaultPlaceholder);

  // create submit callback
  const submitHandler = useCallback(() => {
    ContactAPI.trackSubmit(backend);
  }, [backend]);

  // loads a question by id
  const loadQuestion = useCallback(
    (id: number) => {
      return ContactAPI.question(backend, id, placeholder);
    },
    [backend],
  );

  // part of public API
  // set category as search filter &
  // set flag to call search on empty text
  const setCategory = (categoryId: number | null, triggerSearch: boolean = true) => {
    setSearchCategory(categoryId);
    setForceSearch(triggerSearch);
  };
  const form = element.closest('form');
  const form_ = form instanceof HTMLFormElement ? form : /* istanbul ignore next */ null;

  // get form content - text to analyze, add submit handler
  const formSearchValue = useFormContent(form_, analyze, {
    element: submit,
    handler: submitHandler,
  });

  // update search value if form content changes
  useEffect(() => {
    setSearchValue(formSearchValue as string);
  }, [formSearchValue]);

  // reset selected question if search value changes
  useEffect(() => {
    setSelectedQuestion(null);
  }, [searchValue]);

  // search for questions
  const { questions: searchQuestions, inputTooShort } = useQuestionSearch(
    searchValue,
    searchCategory,
    placeholder,
    forceSearch,
  );

  // create reference api
  /* istanbul ignore next */
  useImperativeHandle(ref, () => ({
    loadQuestion,
    submit: submitHandler,
    search: (value: string) => setSearchValue(value),
    setCategory,
    setPlaceholder,
  }));

  // dispatch categories loaded event with allowed categories
  const pageEvent = useEventContext<ContactEvents>();
  useEffect(() => {
    pageEvent.dispatchCategoriesLoaded(categories);
  }, [categories]);

  // use default questions if
  // no search has been performed yet
  // and default question are available
  let questions = [];
  let headline = '';
  if (searchQuestions != null) {
    questions = searchQuestions;
    headline = config.loc('question_found_header');
  } else {
    questions = defaultQuestions || [];
    headline = config.loc('placeholder_question_found_header');
  }

  // Set the question as selected and lazy load its answers
  const onQuestionSelect = async (question: Question | null) => {
    setSelectedQuestion(question?.id);
  };

  // render contact
  return (
    <FeedbackWrapper api={ContactAPI}>
      <div className={`${config.generateClassName('contact')} notranslate`} translate="no">
        <QuestionList
          questions={questions}
          headline={headline}
          selectedQuestionId={selectedQuestion}
          onQuestionSelect={onQuestionSelect}
          renderEmptyList={() =>
            inputTooShort === true ? (
              <>{config.loc('placeholder')}</>
            ) : (
              <>{config.loc('emptylist')}</>
            )
          }
          placeholder={placeholder}
          onError={() => {
            /* empty */
          }}
        />

        {config.isPoweredByActive && (
          <Footer
            productLink={`https://omq.ai/products/contact/?utm_source=contact&utm_medium=product&utm_campaign=${account}&utm_content=${apiKey}`}
            productName="Contact Form software"
          />
        )}
      </div>
    </FeedbackWrapper>
  );
}

export type ContactRefType = {
  loadQuestion: (id: number) => Promise<Question>;
  submit: () => void;
  search: (value: string) => void;
  setCategory: (categoryId: number, triggerSearch?: boolean) => void;
  setPlaceholder: (placeholder: Placeholder) => void;
};

const ForwardContact = forwardRef<ContactRefType, ContactProps>(Contact);
export { ForwardContact as Contact };
