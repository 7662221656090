import { useCallback } from 'react';

import { SearchDelayTypes, useBackendContext, useDelayedSearch } from '@omq/shared';
import type { Placeholder } from '@omq/types';

import type { ContactSearchParams, ContactSearchResult } from '../api/contact';
import { ContactAPI } from '../api/contact';
const defaultSearchResult = {
  questions: null,
  inputTooShort: true,
};

/**
 * Search hook.
 * Calls search on server.
 *
 * @param {string} searchValue - Search text
 * @param {Placeholder} placeholder - placeholders for answers
 * @param {number} [category] - category search filter
 * @param {boolean} [forceSearch] - force search
 *
 * @returns {ContactSearchResult}
 */
export function useQuestionSearch(
  searchValue: string,
  category: number | null = null,
  placeholder?: Placeholder,
  forceSearch?: boolean,
): ContactSearchResult {
  const backend = useBackendContext();

  const searchFunc = useCallback(
    (cancelTokenSource) => {
      const params = {} as ContactSearchParams;

      if (category != null) {
        params.category = category;
      }

      return ContactAPI.search(backend, searchValue, placeholder, params, cancelTokenSource.token);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, category],
  );

  return useDelayedSearch<ContactSearchResult>({
    type: SearchDelayTypes.CONTACT,
    searchValue,
    searchFunc,
    defaultResult: defaultSearchResult,
    forceSearch,
  });
}
