import { useEffect } from 'react';
import { Backend } from '@omq/shared';

type SubmitHelpSessionProps = {
  account: string;
};

const OMQ_HELP_COOKIE = 'omq-help-cookie';

/**
 * Read all cookies and filter for omq help cookies.
 * Return apikey & session id of each cookie.
 *
 * @returns {any[]}
 */
function readHelpCookies(): any[] {
  const cookies = document.cookie.split(';');
  const result = [];
  const apiKeyMap = {};

  // check each cookie
  cookies.forEach((cookie) => {
    // if it's a help cookie
    if (!cookie.match(OMQ_HELP_COOKIE)) {
      // not omq cookie - do nothing
      return;
    }

    // get cookie data
    const data = cookie
      .trim()
      .substring(OMQ_HELP_COOKIE.length + 1)
      .split('=');

    // get session id
    const sessionId = JSON.parse(decodeURIComponent(data[1])).session;
    const apiKey = data[0];

    // check if apikey already is marked for submit
    if (apiKeyMap[apiKey]) {
      return;
    }

    // add to results
    result.push({
      apiKey,
      sessionId,
    });

    // mark api key
    // do not submit both cookies for same api key
    apiKeyMap[apiKey] = true;
  });

  return result;
}

/**
 * Component sends submit server request for every stored
 * session of OMQ Help or service center.
 *
 * @param {SubmitHelpSessionProps} props - component props
 *
 * @returns {JSX.Element}
 */
export function SubmitHelpSession(props: SubmitHelpSessionProps): JSX.Element {
  const { account } = props;

  useEffect(() => {
    // read all help or service center cookies
    const cookies = readHelpCookies();

    // send submit for each cookie
    cookies.forEach((cookie) => {
      const backend = new Backend(account, cookie.apiKey, 'help');
      backend.setSession(parseInt(cookie.sessionId));

      backend.post('/submit');
    });
  }, [account]);

  // render nothing
  return null;
}
