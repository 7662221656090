import type { KeyboardEvent, SyntheticEvent } from 'react';
import { Fragment, useRef, useState } from 'react';
import type { Question } from '@omq/types';
import {
  useBackendContext,
  useComponentMounted,
  useConfigContext,
  useEventContext,
} from '@omq/shared';

import { ContactAPI } from '../../api/contact';
import { AnswerList } from '../answer-list/answer-list';
import { ContactEvents } from '../../events/contact-events';
import { ConfigAnswerDisplayTypes } from '@omq/types';
import { usePrintQuestion } from '@omq/shared/src/hooks/print-question';

// type for selection handler
type QuestionSelectionHandler = (id: Question | null) => void;

/**
 * Type for component properties.
 */
type QuestionListItemProps = {
  question: Question;
  isActive: boolean;
  onSelect: QuestionSelectionHandler;
};
/**
 * Renders content of a single question.
 *
 * @param {QuestionListItemProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
export function QuestionListItem({
  question,
  isActive,
  onSelect,
}: QuestionListItemProps): JSX.Element {
  const config = useConfigContext();
  const backend = useBackendContext();
  const eventDispatcher = useEventContext<ContactEvents>();
  const linkRef = useRef<HTMLAnchorElement>();

  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState<boolean>(false);

  // Opens the browser's print dialog and applies print specific CSS
  // to be able to print the active item
  usePrintQuestion({
    isPrintEnabled: isPrintDialogOpen,
    setIsPrintEnabled: setIsPrintDialogOpen,
    filePath: '/contact/assets/print-style.css',
  });

  // scroll to activated item
  useComponentMounted(() => {
    const link = linkRef.current as HTMLElement;
    if (isActive && link != null && link.scrollIntoView != null) {
      link.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isActive]);

  // build class name
  let className = config.generateClassName('question-list-item');
  if (isActive) {
    className += ` ${config.generateClassName('question-list-item--is-active')}`;
  }

  const isInline = config.answerDisplayType === ConfigAnswerDisplayTypes.INLINE;

  // Rotate the toggle icon: For popup, it points to the right,
  // for inline, it points to the bottom when closed and to the top when opened
  let toggleIconClassName = config.generateClassName('question-list__toggle-icon');

  if (isInline) {
    toggleIconClassName += isActive
      ? ` ${config.generateClassName('question-list__toggle-icon--up')}`
      : ` ${config.generateClassName('question-list__toggle-icon--down')}`;
  }

  // create click handler
  const handleClick = (event: SyntheticEvent | KeyboardEvent) => {
    event.preventDefault();

    // Do not close the question if the print button has been clicked
    if (
      onSelect != null &&
      !(event.target as HTMLElement)?.classList.contains('omq-question-list__print-button') &&
      !(event.target as HTMLElement)?.classList.contains('omq-question-list__print-icon')
    ) {
      onSelect(isActive ? null : question);
    }

    if (!isActive) {
      eventDispatcher.dispatchQuestionClick(question);
      ContactAPI.trackView(backend, question.id);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.which === 13 || event.key === 'Enter') {
      handleClick(event);
    }
  };

  // render component
  return (
    <Fragment>
      <div className={config.generateClassName('question-list-item__wrapper')}>
        <a
          className={className}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          aria-controls={`aria-answers-${question.id}`}
          id={`aria-question-${question.id}`}
          aria-expanded={isActive}
          title={question.body}
          ref={linkRef}
          onKeyPress={handleKeyPress}>
          <p className={config.generateClassName('question-list-item__body')}>{question.body}</p>
          {isActive && config.isPrintingActive && isInline && (
            <button
              onClick={() => setIsPrintDialogOpen(true)}
              title={config.loc('print')}
              className={config.generateClassName('question-list__print-button')}>
              <i className={config.generateClassName('question-list__print-icon')} />
            </button>
          )}
          <span className={toggleIconClassName} />
        </a>

        {isActive && (
          <AnswerList
            question={question}
            headline={question.body}
            onClose={() => {
              onSelect(null);
              if (linkRef.current != null) {
                linkRef.current.focus();
              }
            }}
          />
        )}
      </div>
    </Fragment>
  );
}
