import { useState, useEffect } from 'react';

declare global {
  interface Window {
    CKEDITOR: any;
  }
}

const { CKEDITOR } = window;

const eventTypes = ['keyup', 'drop', 'cut', 'paste', 'input'];

const DEFAULT_ANALYZE_SELECTOR = '.omq-analyze, .userlike-analyze, mapp-analyze';
const DEFAULT_SUBMIT_SELECTOR = '.omq-submit, .userlike-submit, mapp-submit';

/**
 * Function to extract values from html elements.
 */
const ExtractValues = {
  input: (element: HTMLInputElement) => element.value,
  textarea: (element: HTMLTextAreaElement) => {
    // if CKEditor is available
    if (CKEDITOR != null) {
      // check if there is a CKEditor instance with the given element
      const instance: typeof CKEDITOR = Object.values(CKEDITOR.instances).find((instance) => {
        return (
          // @ts-ignore
          (instance.element == null) != null && instance.element.$ === element
        );
      });

      // if instance exists, get data from CKEditor
      if (instance != null) {
        return instance.getData();
      }
    }

    // return text area content
    return element.value;
  },
  checkbox: (element: HTMLInputElement) => (element.checked ? element.value : ''),
  radio: (element: HTMLInputElement) => (element.checked ? element.value : ''),
  select: (element: HTMLSelectElement) => {
    const option = element.querySelector(
      'option:not(:disabled):checked[value]',
    ) as HTMLOptionElement;
    return option != null ? option.innerText : '';
  },
  default: (element: HTMLElement) => element.innerText,
};

/**
 * Reducer function.
 * Combine content of passed elements to result.
 *
 * @param {string} result - reducer value
 * @param {HTMLElement} element - current element
 *
 * @returns {string}
 */
function formValueReducer(result, element): string {
  let type = element.nodeName.toLowerCase();

  // check type of special inputs
  if (type === 'input' && ['radio', 'checkbox'].includes(element.type)) {
    type = element.type;
  }

  // get value of element
  const extractor = ExtractValues[type] || ExtractValues.default;
  const value = extractor(element).trim();

  // add value to result
  return value === '' ? result : `${result} ${value}`.trim();
}

/**
 * Get analyze content from form.
 *
 * @param {HTMLFormElement|null} form - form element
 * @param {?string} selector - css selector to detect elements to analyze
 *
 * @returns {string}
 */
function getFormInputValues(
  form: HTMLFormElement | null,
  selector: string | null | undefined,
): string | NodeListOf<Element> {
  // if customer selector was set
  if (selector != null) {
    const domElements = document.querySelectorAll(selector);
    return Array.prototype.reduce.call(domElements, formValueReducer, '');
  }
  // if default analyze selector was found
  else if (document.querySelector(DEFAULT_ANALYZE_SELECTOR) != null) {
    const domElements = document.querySelectorAll(DEFAULT_ANALYZE_SELECTOR);
    return Array.prototype.reduce.call(domElements, formValueReducer, '');
  }
  // if form is available, read value of textarea
  else if (form != null) {
    const textarea = form.querySelector('textarea');
    return Array.prototype.reduce.call([textarea], formValueReducer, '');
  } else {
    /* istanbul ignore next */
    return '';
  }
}

/*
 * TODO: seperate into two hooks
 *  e.g.: useFormContent & useFormSubmit
 *
 * **/
export function useFormContent(
  form: HTMLFormElement | null,
  formElements: string | null | undefined,
  submitOptions: {
    element: string | null | undefined;
    handler: () => void;
  },
): string | NodeListOf<Element> {
  const [formContent, setFormContent] = useState(getFormInputValues(form, formElements));

  // add handlers to detect changes of form content
  useEffect(() => {
    const listeners = {};
    eventTypes.forEach((eventType: string) => {
      listeners[eventType] = (event: Event) => {
        const { target } = event;

        /* istanbul ignore if */
        if (!(target instanceof HTMLElement)) {
          return;
        }

        // if custom analyze class was set, check if event target matches
        if (formElements != null && !target.matches(formElements)) {
          return;
        }
        // check if default class name is used, and if target matches
        else if (
          document.querySelector(DEFAULT_ANALYZE_SELECTOR) != null &&
          !target.matches(DEFAULT_ANALYZE_SELECTOR)
        ) {
          return;
        }
        // check if element was part of our form
        // only check for form if contact is part of a form
        else if (form != null && target.closest('form') !== form) {
          return;
        }

        setFormContent(getFormInputValues(form, formElements));
      };

      document.addEventListener(eventType, listeners[eventType]);
    });

    /* istanbul ignore next */
    return () => {
      eventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, listeners[eventType]);
      });
    };
  }, [form, formElements]);

  // detect & analyze CKEditor content
  useEffect(() => {
    if (CKEDITOR == null) {
      return;
    }

    // get all CKEditor instances that are part of the form
    // and match the given selectors
    const instances: Array<typeof CKEDITOR> = Object.values(CKEDITOR.instances).filter(
      (instance) => {
        // @ts-ignore
        if (instance.element == null) {
          return false;
        }

        // @ts-ignore
        const textarea = instance.element.$;

        // check if element is part of the form
        if (form != null && textarea.closest('form') !== form) {
          return false;
        }

        // if selector is given, check if it matches the element
        if (formElements != null) {
          return textarea.matches(formElements);
        }

        // if default selector is used, check if it matches
        if (document.querySelector(DEFAULT_ANALYZE_SELECTOR) != null) {
          return textarea.matches(DEFAULT_ANALYZE_SELECTOR);
        }

        return true;
      },
    );

    // change handler for CKEditor
    function handleChange() {
      setFormContent(getFormInputValues(form, formElements));
    }

    // add change handler to all instances
    instances.forEach((instance) => {
      instance.on('change', handleChange);
    });

    // clean up
    return () => {
      // remove change handlers from all instances
      instances.forEach((instance) => {
        instance.removeListener('change', handleChange);
      });
    };
  }, [form, formElements]);

  const { element: submitElementSelector, handler: submitHandler } = submitOptions;

  // add listeners to form submit
  useEffect(() => {
    const selector =
      submitElementSelector != null ? submitElementSelector : DEFAULT_SUBMIT_SELECTOR;
    const submitElement = document.querySelector(selector);

    // if custom submit element was set, but does not exist
    // do not add click handler
    /* istanbul ignore if */
    if (submitElementSelector != null && submitElement == null) {
      return;
    }

    // if element exists
    if (submitElement != null) {
      // add click handler
      submitElement.addEventListener('click', submitHandler);

      // clean up function - remove click handler
      /* istanbul ignore next */
      return () => {
        if (submitElement != null) {
          submitElement.removeEventListener('click', submitHandler);
        }
      };
    }

    // of no custom submit handling was detected
    // add handle form submit event
    /* istanbul ignore else */
    if (form != null) {
      form.addEventListener('submit', submitHandler);

      // clean up function - remove submit handler
      /* istanbul ignore next */
      return () => {
        if (form != null) {
          form.removeEventListener('submit', submitHandler);
        }
      };
    }
  }, [form, submitElementSelector, submitHandler]);

  return formContent;
}
