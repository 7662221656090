import type { Question } from '@omq/types';
import { ConfigAnswerDisplayTypes } from '@omq/types';

import { QuestionAnswerModal, AnswersComponent, useConfigContext } from '@omq/shared';
/**
 * Type for component properties.
 */
type AnswerListProps = {
  question: Question;
  headline: string;
  onClose: () => void;
};

/**
 * Component to display the content of one or more answers passed in props.
 *
 * @param {AnswerListProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.4
 *
 * @returns {JSX.Element}
 */
export function AnswerList({ question, headline, onClose }: AnswerListProps): JSX.Element {
  const config = useConfigContext();

  const { answers } = question;

  // show nothing if there are no answers
  if (answers == null || answers.length === 0) {
    return null;
  }

  const isModal = config.answerDisplayType === ConfigAnswerDisplayTypes.POPUP;
  const renderedAnswers = (
    <AnswersComponent question={question} className="contact" isModal={isModal} />
  );

  if (isModal) {
    return (
      <QuestionAnswerModal headline={headline} className="contact" onClose={onClose}>
        {renderedAnswers}
      </QuestionAnswerModal>
    );
  }

  // render answers inside of popover
  return renderedAnswers;
}
